import React, { useState } from 'react';

const EnquiryFormPage = () => {
    const [selectedInterest, setSelectedInterest] = useState([]);
    const [showExamSection, setShowExamSection] = useState(false);
    const [showCounsellingSection, setShowCounsellingSection] = useState(false);

    const handleInterestSelection = (interests) => {
        setSelectedInterest(interests);

        const examsSelected = interests.includes('Exams');
        const counsellingSelected = interests.includes('Counselling');

        if (examsSelected && counsellingSelected) {
            setShowExamSection(true);
            setShowCounsellingSection(true);
        } else if (examsSelected) {
            setShowExamSection(true);
            setShowCounsellingSection(false);
        } else if (counsellingSelected) {
            setShowExamSection(false);
            setShowCounsellingSection(true);
        }
    };

    const handleCheckboxChange = (e) => {
        const selectedInterestValue = e.target.value;

        const updatedInterest = e.target.checked
            ? [...selectedInterest, selectedInterestValue]
            : selectedInterest.filter((item) => item !== selectedInterestValue);
        handleInterestSelection(updatedInterest);
    };

    return (
        <div className='enquiry-container container-fluid padding-x  margin-top'>
            <div className='text-center pt-3'>
                <h1 className='heading'>Interest <span className="highlight">Form</span> </h1>
                <p className="sub-heading">Empower Your Journey: Start Counselling Today</p>
            </div>

            {/* design form - bootstrap - working */}
            <div className='enquiry-form-container'>
                <form action='https://docs.google.com/forms/d/e/1FAIpQLSdKnGLvY0I3jrZi4LjNeelxpmc66v65G9V9Dz3JlnThcH_WRg/formResponse'>
                    {/* section 1 - basic info */}
                    <div className="form-section">
                        <div className="row">
                            {/* name */}
                            <div className="col-lg-6 col-12">
                                <div class="form-floating mb-3">
                                    <input type="text" className="form-control" placeholder="Full Name" required name='entry.2010962769' />
                                    <label htmlFor='entry.2010962769'>Full Name <span className='text-red'>*</span></label>
                                </div>
                            </div>
                            {/* email */}
                            <div className="col-lg-6 col-12">
                                <div class="form-floating mb-3">
                                    <input type="email" className="form-control" placeholder="Email" required name='entry.1269709806' />
                                    <label htmlFor='entry.1269709806'>Email address <span className='text-red'>*</span></label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {/* contact */}
                            <div className="col-lg-6 col-12">
                                <div class="form-floating mb-3">
                                    <input type="number" className="form-control" placeholder="Contact No." required name='entry.1732216698' />
                                    <label htmlFor='entry.1732216698'>Contact No. <span className='text-red'>*</span></label>
                                </div>
                            </div>
                            {/* city */}
                            <div className="col-lg-6 col-12">
                                <div class="form-floating mb-3">
                                    <input type="text" className="form-control" placeholder="Your City" name='entry.1526742396' />
                                    <label htmlFor='entry.1526742396'>Your City</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* section 2 - interested in */}
                    <div className="form-section">
                        <label className='form-label' htmlFor='entry.1331986623'><b>Interested In:</b></label>
                        <div className="row">
                            {/* checkbox - exam */}
                            <div className="col-lg-2 col-12">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="entry.1331986623" id='entry.1331986623' value={'Exams'}
                                        onChange={handleCheckboxChange}
                                       
                                    />
                                    <label className="form-check-label" >
                                        Exams
                                    </label>
                                </div>

                            </div>
                            {/* checkbox - counselling */}
                            <div className="col-lg-2 col-12">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="entry.1331986623" id='entry.1331986623' value={'Counselling'}
                                        onChange={handleCheckboxChange}
                                       
                                    />
                                    <label className="form-check-label">
                                        Counselling
                                    </label>
                                </div>

                            </div>
                            {/* checkbox - sop/lor/resume */}
                            <div className="col-lg-3 col-12">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="entry.1331986623" id='entry.1331986623' value={'SOP/LOR/Resume'}
                                        onChange={handleCheckboxChange}
                                       
                                    />
                                    <label className="form-check-label">
                                        SOP/LOR/Resume
                                    </label>
                                </div>

                            </div>
                            {/* checkbox - visa/pr */}
                            <div className="col-lg-3 col-12">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="entry.1331986623" id='entry.1331986623' value={'PR/Visa'}
                                        onChange={handleCheckboxChange}
                                        
                                    />
                                    <label className="form-check-label">
                                        PR/Visa
                                    </label>
                                </div>

                            </div>
                            {/* checkbox - other */}
                            <div className="col-lg-2 col-12">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="entry.1331986623" id='entry.1331986623' value={'Other'}
                                        onChange={handleCheckboxChange}
                                        
                                    />
                                    <label className="form-check-label">
                                        Other
                                    </label>
                                </div>

                            </div>
                        </div>


                    </div>

                    {/* section 3 - exam */}
                    {showExamSection && (
                        <div className="form-section">
                            <label className='form-label' htmlFor='entry.671147784'><b>Exams</b></label>
                            <div className="row">
                                {/* checkbox - ielts */}
                                <div className="col-lg-3 col-12">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name='entry.671147784' id='entry.671147784' value={'IELTS'}
                                        />
                                        <label className="form-check-label">
                                            IELTS
                                        </label>
                                    </div>
                                </div>
                                {/* checkbox - pte */}
                                <div className="col-lg-3 col-12">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name='entry.671147784' id='entry.671147784' value={'PTE'}
                                        />
                                        <label className="form-check-label">
                                            PTE
                                        </label>
                                    </div>
                                </div>
                                {/* checkbox - gre */}
                                <div className="col-lg-3 col-12">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name='entry.671147784' id='entry.671147784' value={'GRE'}
                                        />
                                        <label className="form-check-label">
                                            GRE
                                        </label>
                                    </div>
                                </div>
                                {/* checkbox - other */}
                                <div className="col-lg-3 col-12">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name='entry.671147784' id='entry.671147784' value={'Other'}
                                        />
                                        <label className="form-check-label">
                                            Other
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                    }


                    {/* section 4 - counselling*/}
                    {showCounsellingSection && (
                        <div className="form-section">
                            <label className='form-label' htmlFor='entry.257288811'><b>Counselling</b></label>
                            <div className="row">
                                {/* checkbox - usa */}
                                <div className="col-lg-3 col-12">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name='entry.257288811' id='entry.257288811' value={'USA'}
                                        />
                                        <label className="form-check-label">
                                            USA
                                        </label>
                                    </div>
                                </div>
                                {/* checkbox - germany */}
                                <div className="col-lg-3 col-12">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name='entry.257288811' id='entry.257288811' value={'Germany'}
                                        />
                                        <label className="form-check-label">
                                            Germany
                                        </label>
                                    </div>
                                </div>
                                {/* checkbox - uk */}
                                <div className="col-lg-3 col-12">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name='entry.257288811' id='entry.257288811' value={'UK'}
                                        />
                                        <label className="form-check-label">
                                            UK
                                        </label>
                                    </div>
                                </div>
                                {/* checkbox - other */}
                                <div className="col-lg-3 col-12">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name='entry.257288811' id='entry.257288811' value={'Other'}
                                        />
                                        <label className="form-check-label">
                                            Other
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}


                    {/* section 5 - other details */}
                    <div className="form-section">

                        {/* preferred intake */}
                        <label className='form-label'><b>Preferred Intake</b></label>
                        <div className="row">
                            <div className="col-lg-6 col-12">
                                <div class="form-floating mb-3">
                                    <input type="number" className="form-control" placeholder="Year" name='entry.1169936368' />
                                    <label htmlFor='entry.1169936368'>Year</label>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <div class="form-floating mb-3" title='fall, spring, etc.'>
                                    <input type="text" className="form-control" placeholder="Session" name='entry.256685110' />
                                    <label htmlFor='entry.256685110'>Session</label>
                                </div>
                            </div>
                        </div>
                        {/* level of education */}
                        <label className='form-label'><b>Preferred Level of Education</b></label>
                        <div className="row">
                            {/* bachelors */}
                            <div className="col-lg-3 col-12">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name='entry.60853952'
                                        id='entry.60853952'
                                        value={"Bachelor's"}
                                    />
                                    <label className="form-check-label">
                                        Bachelor's
                                    </label>
                                </div>
                            </div>
                            {/* asters */}
                            <div className="col-lg-3 col-12">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name='entry.60853952'
                                        id='entry.60853952'
                                        value={"Master's"}
                                    />
                                    <label className="form-check-label">
                                        Master's
                                    </label>
                                </div>
                            </div>
                            {/* phd */}
                            <div className="col-lg-3 col-12">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name='entry.60853952'
                                        id='entry.60853952'
                                        value={"PhD"}
                                    />
                                    <label className="form-check-label">
                                        PhD
                                    </label>
                                </div>
                            </div>
                            {/* other level of education */}
                            <div className="col-lg-3 col-12">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name='entry.60853952'
                                        id='entry.60853952'
                                        value={"Other"}
                                    />
                                    <label className="form-check-label">
                                        Other
                                    </label>
                                </div>
                            </div>
                        </div>


                        {/* work experience */}
                        <label className='form-label mt-3' htmlFor='entry.1307002349'><b>Work Experince</b></label>
                        <div className="row">
                            <div className="col-lg-1 col-12">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name='entry.1307002349'
                                        id='entry.1307002349'
                                        value={'Yes'}
                                    />
                                    <label className="form-check-label">
                                        Yes
                                    </label>
                                </div>
                            </div>
                            <div className="col-lg-1 col-12">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name='entry.1307002349'
                                        id='entry.1307002349'
                                        value={'No'}
                                    />
                                    <label className="form-check-label">
                                        No
                                    </label>
                                </div>
                            </div>
                        </div>

                        {/* recent qualififcation */}
                        <div className="row mt-3">
                            <div className="col-lg-6 col-12">
                                <div class="form-floating mb-3">
                                    <input type="text" className="form-control" placeholder="Recent Qualification" name='entry.1191534506' />
                                    <label htmlFor='entry.1191534506'>Recent Qualification</label>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <div class="form-floating mb-3" title='fall, spring, etc.'>
                                    <input type="text" className="form-control" placeholder="Passout Year" name='entry.756816748' />
                                    <label htmlFor='entry.756816748'>Passout Year</label>
                                </div>
                            </div>
                        </div>

                        {/* additional details */}
                        <div className="form-floating my-3">
                            <textarea
                                className="form-control"
                                placeholder="Additional Details"
                                name='entry.2019477743'
                            />
                            <label htmlFor='entry.2019477743'>Additional Details</label>
                        </div>

                    </div>

                    {/* submit button */}
                    <button type="submit" className='btn btn-outline btn-round'>Submit</button>

                </form>
            </div>

        </div>
    )
}

export default EnquiryFormPage