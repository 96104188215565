import React from 'react'
import { useNavigate } from "react-router-dom";
import circleOrange from '../assets/images/circle-orange.png'
import circlePink from '../assets/images/circle-pink.png'
import curveOrange from '../assets/images/curve-orange.png'
import squarePurple from '../assets/images/square-purple.png'
import triangleBlue from '../assets/images/triangle-blue.png'
import triangleGreen from '../assets/images/triangle-green.png'

export default function HomeSection() {
  const navigate = useNavigate();
  return (
    <div className='home-section container-fluid padding-x margin-top'>

    {/* floating elements starts */}
    <div className="home-float">
    <img src={circleOrange} alt="circle-image" className='c1' />
    <img src={circlePink} alt="circle-image" className='c2' />
    <img src={curveOrange} alt="curve-image" className='cu' />
    <img src={squarePurple} alt="square-image" className='s' />
    <img src={triangleBlue} alt="triangle-image" className='t1' />
    <img src={triangleGreen} alt="triangle-image" className='t2' />
    </div>
    {/* floating elements ends */}

        <div className="home-row row">
            <div className="home-left col-lg-5 col-md-12 d-flex flex-column justify-content-center">
                <h1 className="home-title text-uppercase fw-bolder">Get Ready to move forward with  <span> global education</span> opportunities</h1>
                <p className="home-text">
                Embark on an  
                <em><b> exceptional journey with us </b></em>
                  to earn a profession you deserve!
                </p>
                <button className="btn btn-round btn-outline btn-cta btn-hover" onClick={() => navigate("/contactus")} >Book Expert Advice</button>
            </div>
            <div className="home-right col-lg-7 col-md-12 text-center">
                <img src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/home-img.jpg?updatedAt=1692685834334" alt="study abroad" />
            </div>
        </div>
    </div>
  )
}
