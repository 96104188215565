import React from "react";
import { Accordion } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css/bundle";
import resultData from "../../assets/data/resultData";


const servicesData = [
  {
    id: 1,
    title: "Counseling",
    desc: "Embark on your study abroad journey with confidence, guided by our team of professional counselors at Skydoor in Indore. Experience personalized one-on-one sessions where we delve into your career aspirations and academic interests to help you find the perfect country and course. Our counselors bring years of expertise to the table, ensuring that every recommendation is tailored to your unique needs and goals. Trust Skydoor to provide you with the support and guidance you need to make informed decisions and embark on a rewarding study abroad experience.",
    color: "text-blue",
  },
  {
    id: 2,
    title: "Exam PREP",
    desc: "Prepare for exam success with Skydoor's expert faculty, providing comprehensive preparation both online and offline in Indore, Indore. Our personalized guidance ensures effective preparation for language proficiency tests like IELTS, GRE, and SAT. Benefit from our experienced instructors, tailored curriculum, and flexible learning options.",
    color: "text-yellow",
  },
  {
    id: 3,
    title: "Applications",
    desc: "Your application is the cornerstone of your admission journey, and at Skydoor, we understand its pivotal role. Our proficient team is dedicated to navigating the intricacies of the application process on your behalf. From crafting compelling statements of purpose to meticulously submitting your documents, we ensure that every aspect of your application reflects your strengths and aspirations. With Skydoor by your side, rest assured that your application journey is in expert hands",
    color: "text-green",
  },
  {
    id: 4,
    title: "Visa",
    desc: "Navigating the visa application process can be daunting, but at Skydoor, we've simplified it for you. Our visa services are designed to make your study abroad journey smooth and hassle-free. From guiding you through the required documentation to assisting with interview preparation, we provide comprehensive support to help you secure your student visa with confidence. Trust Skydoor to handle the intricacies of the visa process, allowing you to focus on preparing for your exciting adventure abroad.",
    color: "text-red",
  },
  {
    id: 5,
    title: "Mentorship",
    desc: "Studying and living abroad can be a challenging experience, filled with ups and downs. At SkyDoor, we go beyond the role of a mere consultancy firm and offer valuable mentorship to support you throughout your education abroad endeavors. Our experienced mentors provide personalized guidance, helping you navigate the academic, cultural, and personal aspects of life abroad. From academic support to cultural immersion, SkyDoor is committed to being your trusted mentor every step of the way. ",
    color: "text-gray",
  },
];

const trackRecordData = [
  {
    id: 1,
    title: "Track Record",
    desc: "Our excellent track record and 100% visa rate are a testament to our study abroad services.",
    color: "text-blue",
  },
  {
    id: 2,
    title: "Global Network",
    desc: "Strong connections with renowned universities worldwide.",
    color: "text-yellow",
  },
  {
    id: 3,
    title: "Personalized Guidance",
    desc: "Tailored counseling for individual student needs and aspirations.",
    color: "text-red",
  },
  {
    id: 4,
    title: "Expert Team",
    desc: "Our well-experienced team of professionals will provide a seamless Study abroad journey for you.",
    color: "text-green",
  },
  {
    id: 5,
    title: "End-to-end service",
    desc: "From selecting a university to securing your seat in it, Skydoor has got you covered.",
    color: "text-gray",
  },
  {
    id: 6,
    title: "Guaranteed scholarship success",
    desc: "Assured scholarship support with a commitment to fulfill your study abroad dream.",
    color: "text-blue",
  },
];

const faqData = [
  {
    id: 1,
    question:
      "Which consultancy is the best for study abroad in Indore?",
    answer:
      "With our extensive experience and outstanding results from our students, we can stand as the best study abroad consultants in Indore.",
  },
  {
    id: 2,
    question:
      "What should you look for before choosing your Study Abroad Consultant?",
    answer:
      "When choosing your study abroad consultant, consider factors such as their experience, track record of success, organization’s registration, social media presence, personalized guidance, range of services offered, and affordability.",
  },
  {
    id: 3,
    question:
      "Is Education Abroad expensive and what are the Costs Associated with it?",
    answer:
      "Education abroad can be expensive, with costs varying based on factors like location and program. Expenses typically include tuition fees, accommodation, living expenses, travel, insurance, and visa fees. On average, studying abroad can range from INR 10 lakhs to 40 lakhs per year. However, scholarships and part-time work opportunities can help offset costs. At Skydoor, we provide guidance to manage expenses effectively, ensuring value for your investment in international education. Trust us to help you navigate the financial aspects of studying abroad.",
  },
  {
    id: 4,
    question: "What are the chances of Scholarship?",
    answer:
      "Scholarships are available for international students based on academic merit, financial need, and specific criteria set by universities and organizations. Skydoor assists students in finding and applying for scholarships, making study abroad dreams more affordable. Trust us to guide you through the process with expertise and efficiency.",
  },
  {
    id: 5,
    question: "Which country is best to Study Abroad?",
    answer:
      "The choice of the best country to study abroad depends on various factors, including your academic goals, preferred language of instruction, cultural preferences, and budget. Countries like the United States, the United Kingdom, Canada, Australia, and Germany are renowned for their top-notch universities, diverse academic programs, and vibrant student communities. Each country offers unique advantages, such as cutting-edge research opportunities, industry connections, and cultural immersion experiences. At Skydoor, we provide personalized guidance to help you select the best study destination based on your individual preferences and aspirations. Trust us to assist you in finding the perfect fit for your academic journey abroad.",
  },
  {
    id: 6,
    question: "Which courses are best to pursue from Abroad?",
    answer:
      "The spectrum of courses to pursue abroad extends far beyond traditional disciplines. Alongside Computer Science and Business Management, fields such as Engineering, Medicine, Environmental Science, and Psychology remain highly sought-after for their global relevance and career prospects. Moreover, emerging sectors like Renewable Energy, Data Science, Artificial Intelligence, and Sustainable Development are gaining traction, reflecting evolving industry demands and societal priorities. Additionally, niche fields like Culinary Arts, Hospitality Management, Creative Writing, and Film Production offer unique avenues for exploration and specialization, catering to diverse passions and talents. With a plethora of options available, students can choose courses that align with their interests, aspirations, and the evolving needs of the global workforce, ensuring a fulfilling and rewarding academic journey abroad.",
  },
];

const OverseasEducation = () => {
  const examData = resultData.filter((item) => item.category.match("admit"));
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Overseas education consultant in Indore</title>
        <meta
          name="description"
          content="We are leading overseas education consultants in Indore, thriving with our commitment to guiding and supporting students in their study abroad journey"
        />
        <meta
          name="keywords"
          content="overseas education consultants in indore, best study abroad consultants in indore, study abroad consultants in Indore, abroad education consultants in indore, best overseas education consultants in Indore"
        />
        <meta name="author" content="SkyDoor" />
        <meta name="author" content="Priyanshi Agrawal" />
        <meta
          property="og:title"
          content="overseas education consultant in indore"
        />
        <meta
          property="og:description"
          content="We are leading overseas education consultants in Indore, thriving with our commitment to guiding and supporting students in their study abroad journey"
        />
        <meta property="og:image" content="../assets/images/brand-logo.png" />
        <meta
          property="og:url"
          content="https://skydooredtech.in/overseas-education-consultant/indore"
        />
        <meta name="google-site-verification" content="Je8Ncn0z8hlfldvJ_M8ky-yXOFVJZQ8VARDhY9pyRUc" />
      </Helmet>
      <section className="overseas-container container-fluid padding-x margin-top wave-bg">
         <h1 className="heading padding-yt text-center mb-4">
            Overseas Education{" "}
            <span className="highlight">Consultants in Indore</span>
          </h1>
        <div className="row align-items-center row-gap-3">
          <div className="col-lg-8 col-12 text-center text-md-start ">
          <p>
            Tired of Searching for <strong>Professional Study Abroad Consultants</strong> in
            Indore? Your quest ends here; Skydoor is your <strong>ultimate
            abroad education companion</strong> , bridging the gap between you and your
            dream University. We understand the importance of finding the right
            study abroad consultant, especially in Indore. With a plethora of
            options available, navigating through the maze of choices can be
            overwhelming. That's where Skydoor comes in – as your ultimate
            companion on the journey to fulfilling your dreams of studying
            abroad.
          </p>
            <button
              className="btn btn-round btn-outline btn-hover"
              onClick={() => navigate("/contactus")}
            >Start your Journey Now!</button>
          </div>
          <div className="col-lg-4 col-12 text-lg-end text-center overseas-hero-img">
            <img src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/overseas-indore.svg?updatedAt=1712213270238" alt="" />
          </div>
        </div>

        <div className="padding-yt">
          <div className="padding-yt">
            <h4 className="fw-bold text-center mb-2 mb-md-4">We are the one-stop solution to all your study abroad hassles! offering high-quality services in</h4>
          {servicesData.map((item) => (
            <div key={item.id} className="mb-2 mb-md-4">
              <h2 className={`${item.color} fw-bold`}>{item.title}</h2>
              <p>{item.desc}</p>
            </div>
          ))}
          </div>
          
          <div className="d-flex justify-content-center mt-2">
            <button
              className="btn btn-round btn-outline btn-hover mt-4"
              onClick={() => navigate("/contactus")}
            >
              Start your Journey Now!
            </button>
          </div>
        </div>

        <div className="padding-yt text-center">
          <h1 className="heading padding-yt mb-md-5 mb-3 text-center">
          Our students' excellence is a testament to <span className="highlight">Our Services</span>
          </h1>
          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            breakpoints={{
              300: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
          >
            {examData.map((item, idx) => (
              <SwiperSlide key={item.id}>
                <div key={`items-${idx}`} className="result-item">
                  <img src={item.url} alt={item.category} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="mt-2">
            <button
              className="btn btn-round btn-outline btn-hover mt-4"
              onClick={() => navigate("/contactus")}
            >
              Start your Journey Now!
            </button>
          </div>
        </div>

        <div className="padding-yt text-center">
          <h1 className="heading padding-yt mb-4 text-center">
            Why we are the{" "}
            <span className="highlight">
              best overseas education consultants in Indore
            </span>
          </h1>
          <table className="table table-bordered">
            <tbody>
              {trackRecordData.map((item) => (
                <tr key={item.id}>
                  <td>
                    <p className={`${item.color} fs-5 fw-bold`}>{item.title}</p>
                  </td>
                  <td className="text-start">{item.desc}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="mt-2">
            <button
              className="btn btn-round btn-outline btn-hover mt-4"
              onClick={() => navigate("/contactus")}
            >
              Start your Journey Now!
            </button>
          </div>
        </div>
          
        <div className="padding-yt text-center">
          <h1 className="heading padding-yt mb-4 text-center">
            How can you reach Skydoor -{" "}
            <span className="highlight">Overseas Consultant in Indore</span>
          </h1>
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3680.1482115127046!2d75.88449607489451!3d22.722731927451928!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962e31193cdbcd3%3A0x258a37fa6ee822b0!2sSkyDoor%20EdTech%20International!5e0!3m2!1sen!2sin!4v1693045028179!5m2!1sen!2sin"
            width={600}
            height={350}
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>

        <div className="padding-yt">
          <h1 className="heading padding-yt mb-4 text-center">
            FAQs for{" "}
            <span className="highlight">
              study abroad consultants in Indore
            </span>
          </h1>
          <Accordion defaultActiveKey="0">
            {faqData.map((item) => (
              <Accordion.Item eventKey={`${item.id}`}>
                <Accordion.Header>{item.question}</Accordion.Header>
                <Accordion.Body>{item.answer}</Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </section>
    </>
  );
};

export default OverseasEducation;
