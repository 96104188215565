import React from "react";
import { useNavigate } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import resultData from "../../assets/data/resultData";
import { Helmet } from "react-helmet";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css/bundle";
import TrainingCard from "../../components/cards/training-card/TrainingCard";
import { syllabusData } from "../../assets/data/syllabusData";

const ieltsData = [
  "A language can't be mastered through rote learning; it requires a practical approach. Skydoor IELTS class in Indore focuses on practical learning methods, we not only prepare candidates for the IELTS but also enhance their communication skills for an English-savvy environment.",

  "Our UK-educated faculty members possess over five years of extensive IELTS teaching experience, guaranteeing you a successful journey towards English proficiency.",

  "Achieving a high IELTS band requires a well-defined strategy and consistent practice. At Skydoor IELTS classes, we offer you several mock tests, analyze your performance, provide tailored recommendations, and help you build a solid exam strategy.",
];

const faqData = [
  {
    id: 1,
    question: "What is the duration of your IELTS coaching in Indore?",
    answer: "We provide extensive IELTS classes tailored to students' requirements, we have batches of a month, 45 days, and three months as well.",
  },
  {
    id: 2,
    question: "Does SkyDoor also offer online IELTS classes?",
    answer:
      "Yes, we also offer comprehensive online IELTS classes tailored to your schedule, ensuring you ace your IELTS exam.",
  },
  {
    id: 3,
    question: "Which coaching is best for IELTS coaching in Indore?",
    answer:
      "With the outstanding results of our students in the IELTS exam, we can stand as the best    IELTS coaching in Indore.",
  },
  {
    id: 4,
    question: "What is the syllabus of IELTS?",
    answer:
      "IELTS tests a candidate on his overall English proficiency, assessing a student's listening, reading, writing, and speaking skills in English.",
  },
  {
    id: 5,
    question: "What is the fee for the IELTS exam?",
    answer: "The fee for the IELTS exam is INR 17,000/-",
  },
  {
    id: 6,
    question: "What is the fees for Skydoor IELTS coaching classes in Indore?",
    answer:
      "Our fees vary depending on the duration of the course. Please contact +91-8889000174 for further Information.",
  },
  {
    id: 7,
    question: "What is a good IELTS score?",
    answer:
      "A good IELTS score depends on the university's requirement, however a score ranging between 7- 9 is considered good as it is accepted by most universities.",
  },
  {
    id: 8,
    question: "What is the validity of IELTS score",
    answer:
      "An IELTS score is valid for 2 years",
  },
];

const trainingData = [
  {
    title: "Academic Training",
    headerColor: "#e8f2f9",
    list: [
      "People who are applying to higher education",
      "Evaluate the candidate's capability to learn in English Taught academic program",
    ],
    color : "text-blue"
  },
  {
    title: "General Training",
    headerColor: "#faecee",
    list: [
      "People seeking job opportunities and training programs",
      "Evaluate the candidate’s day to day non-academic English skills",
    ],
    color : "text-red"
  },
];

const IeltsIndore = () => {
  const navigate = useNavigate();
  const examData = resultData.filter((item) => item.category.match("exam"));
  return (
    <>
      <Helmet>
        <title>IELTS coaching in Indore | SkyDoor EdTech International</title>
        <meta
          name="description"
          content="Ace your IELTS exam with Skydoor’s IELTS coaching in Indore; paving the way to your English proficiency"
        />
        <meta
          name="keywords"
          content="IELTS coaching in Indore, IELTS classes in Indore, IELTS preparation in Indore, best IELTS coaching in Indore, best IELTS classes in Indore, IELTS training in Indore"
        />
        <meta name="author" content="SkyDoor" />
        <meta name="author" content="Priyanshi Agrawal" />
        <meta
          property="og:title"
          content="IELTS coaching in Indore | SkyDoor EdTech International"
        />
        <meta
          property="og:description"
          content="Ace your IELTS exam with Skydoor’s IELTS coaching in Indore; paving the way to your English proficiency"
        />
        <meta property="og:image" content="../assets/images/brand-logo.png" />
        <meta
          property="og:url"
          content="https://skydooredtech.in/ielts-coaching-in-indore"
        />
        <meta name="google-site-verification" content="Je8Ncn0z8hlfldvJ_M8ky-yXOFVJZQ8VARDhY9pyRUc" />
      </Helmet>
      <section className="ielts-container container-fluid padding-x margin-top wave-bg">
        <div className="d-flex flex-column justify-content-center">
          <h1 className="heading padding-yt mb-4 text-center">
            Looking for{" "}
            <span className="highlight">IELTS coaching in Indore?</span>
          </h1>
          <div className="row gap-md-5 gap-3 mt-md-4">
            <div className="col-md-6 col-12 text-center text-lg-start">
              <p>
                If yes, then you are in the bullseye. Skydoor offers the <strong>Best
                IELTS Coaching in Indore</strong> . We provide comprehensive <strong>IELTS classes
                in Indore</strong> , backed by our experienced faculty, ensuring
                personalized guidance for your IELTS exam, aiding you in
                achieving your dream IELTS band. Whether you prefer online or
                offline learning, our <strong>IELTS Coaching in Indore</strong>  has got you
                covered. Skydoor also offers interactive online classes,
                providing you with a personalized learning experience.
              </p>
              <button
                className="btn btn-round btn-outline btn-hover mt-2"
                onClick={() => navigate("/contactus")}
              >
                Ace Your IELTS Now!
              </button>
            </div>
            <div className="exam-svg col-md-5 col-12 text-center text-lg-end">
              <img
                src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/ielts-indore.svg?updatedAt=1712124950944"
                alt="english proficiency test"
              />
            </div>
          </div>
        </div>

        <div className="padding-yt">
          <h1 className="heading padding-yt mb-4 text-center">
            What makes us the{" "}
            <span className="highlight">best IELTS coaching in Indore</span>
          </h1>
          {ieltsData.map((text, idx) => {
            const itemKey = idx + "_key";
            return (
              <div key={itemKey} className="d-flex gap-2 gap-md-3 mb-3 mb-md-4">
                <div className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    width="24"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="#41b93c"
                      d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"
                    />
                  </svg>
                </div>
                <div className="">{text}</div>
              </div>
            );
          })}
        </div>
        
        <div className="padding-yt text-center">
          <h1 className="heading padding-yt mb-5 text-center">
          Our students' excellence is a testament to our  <span className="highlight">expertise in IELTS training</span>
          </h1>
          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            breakpoints={{
              300: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
          >
            {examData.map((item, idx) => (
              <SwiperSlide key={item.id}>
                <div key={`items-${idx}`} className="result-item">
                  <img src={item.url} alt={item.category} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="mt-2">
            <button
              className="btn btn-round btn-outline btn-hover mt-4"
              onClick={() => navigate("/contactus")}
            >
              Ace Your IELTS Now!
            </button>
          </div>
        </div>

        <div className="d-flex flex-column justify-content-center text-center padding-yt ">
          <h1 className="heading padding-yt">
          IELTS In a
            <span className="highlight"> nutshell</span>
          </h1>
          <div className="row row-gap-3 align-items-center">
            <div className="exam-svg col-lg-4 col-12 text-center">
              <div
                className="border rounded-circle d-flex justify-content-center align-items-center mx-auto overflow-hidden"
                style={{
                  width: "180px",
                  height: "180px",
                }}
              >
                <img
                  src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/IELTS_logo.svg.png?updatedAt=1696334899361"
                  alt="english proficiency test"
                  style={{
                    width: "140px",
                  }}
                />
              </div>
            </div>
            <div className="col-lg-8 col-12 text-center text-lg-start">
              <p>
             <strong>International English Language Testing System (IELTS)</strong>  is an English proficiency test conducted by IDP (International Development Program),  which tests a candidate's speaking, listening, writing, and reading skills in English. It is a widely recognized English proficiency test for both academic and general training, accepted by most universities, employers, government and professional bodies abroad.
              </p>
            </div>
          </div>
        </div>

        <div className="padding-yt text-center">
          <h1 className="heading padding-yt mb-5 text-center">
            IELTS Academic Vs General Training by <br />
            <span className="highlight">Skydoor IELTS Classes in Indore</span>
          </h1>
          <div className="d-flex flex-column flex-md-row align-items-center justify-content-md-center gap-5">
            {trainingData.map((item) => (
              <TrainingCard
                key={item.title}
                title={item.title}
                list={item.list}
                headerColor={item.headerColor}
                color = {item.color}
              />
            ))}
          </div>
          <div className="mt-2">
            <button
              className="btn btn-round btn-outline btn-hover mt-4"
              onClick={() => navigate("/contactus")}
            >
              Ace Your IELTS Now!
            </button>
          </div>
        </div>

        <div className="padding-yt text-center">
          <h1 className="heading padding-yt mb-4 text-center">
          IELTS Academic Exam Structure by
            <span className="highlight"> Skydoor IELTS Coaching in Indore</span>
          </h1>
          <div className="dashboard-card-container mt-md-5">
            {syllabusData.map((data) => (
              <div className="dashboard-card" key={data.id}>
                <div className="dashboard-icon syllabus">{data.svg}</div>
                <h1
                  className="counter heading"
                  style={{
                    fontSize: "1.5rem",
                  }}
                >
                  {data.counterNext}
                </h1>
                <p className="counter-info">
                  <small>Duration </small><br />
                  {data.counterInfo}
                </p>
              </div>
            ))}
          </div>
          <div className="mt-2">
            <button
              className="btn btn-round btn-outline btn-hover mt-4"
              onClick={() => navigate("/contactus")}
            >
              Ace Your IELTS Now!
            </button>
          </div>
        </div>

        <div className="padding-yt">
          <h1 className="heading padding-yt mb-4 text-center">
            FAQs-{" "}
            <span className="highlight">Skydoor IELTS coaching in Indore</span>
          </h1>
          <Accordion defaultActiveKey="0">
            {faqData.map((item) => (
              <Accordion.Item eventKey={`${item.id}`}>
                <Accordion.Header>{item.question}</Accordion.Header>
                <Accordion.Body>{item.answer}</Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
          <div className="mt-2">
            <button
              className="btn btn-round btn-outline btn-hover mt-4"
              onClick={() => navigate("/contactus")}
            >
              Ace Your IELTS Now!
            </button>
          </div>
        </div>

      </section>
    </>
  );
};

export default IeltsIndore;
