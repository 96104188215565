const countryData = [
    {
        id: 1,
        url:'https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/usa-map.png?updatedAt=1692686235101',
        country:'USA'
    },
    {
        id: 2,
        url:'https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/germany-map.png?updatedAt=1692685961151',
        country:'germany'
    },
    {
        id: 3,
        url:'https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/canada-map.png?updatedAt=1692685961137',
        country:'canada'
    },
    {
        id: 4,
        url:'https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/uk-map.png?updatedAt=1692685961146',
        country:'Uk'
    },
    {
        id: 5,
        url:'https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/dubai-map.png?updatedAt=1692685961066',
        country:'dubai'
    },
    {
        id: 6,
        url:'https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/australia-map.png?updatedAt=1692685961123',
        country:'australia'
    },
    {
        id: 7,
        url:'https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/europe-map.png?updatedAt=1692720982893',
        country:'All European Countries'
    },
    {
        id: 8,
        url:'https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/irland-map.png?updatedAt=1692721516729',
        country:'Ireland'
    },

]

export default countryData;
