import React from "react";
import { useNavigate } from "react-router-dom";

const NavbarSEO = () => {
  const navigate = useNavigate();

  return (
    <div className="nav-main fixed-top  padding-x">
      {/* social icons */}
      <div className="nav-top d-flex justify-content-md-end justify-content-between gap-md-2 py-1">
        <div><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" /></svg>
                        <a href="tel:+918889000174" target='_blank' rel='noreferrer'>+91-8889000174</a></div>
        <div>
          <a
            href="https://wa.me/918889000174?text=Hey there! I am excited to discover the educational opportunities Abroad."
            className="px-1"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/whatsaap-icon.svg?updatedAt=1696853805393"
              alt="whatsaap icon"
            />
          </a>

          <a
            href="https://www.linkedin.com/company/skydooredtech/"
            className="px-1"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/linkedin_icon.png?updatedAt=1693048244693"
              alt="linkedin icon"
            />
          </a>

          <a
            href="https://www.instagram.com/skydooredtech/?hl=en"
            className="px-1"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/instagram_icon.png?updatedAt=1693048312497"
              alt="instagram icon"
            />
          </a>

          <a
            href="https://www.facebook.com/skydooredtech"
            className="px-1"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/facebook_icon.png?updatedAt=1693048244581"
              alt="facebook icon"
            />
          </a>
          <a
            href="https://g.page/r/CbAi6G76N4olEBM/"
            className="px-1"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/google-icon.png?updatedAt=1696853339015"
              alt="google page icon"
            />
          </a>
        </div>
      </div>
      <nav className="navbar navbar-expand-sm">
        <div className="container-fluid">
          <img
            className="navbar-brand"
            src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/brand-logo.png?updatedAt=1692686281099"
            alt="brand-logo"
            onClick={() => navigate("/")}
          />
          <button
            className="btn btn-round nav-btn btn-hover"
            onClick={() => navigate("/contactus")}
          >
            Start Evaluation
          </button>
        </div>
      </nav>
    </div>
  );
};

export default NavbarSEO;
