import React from "react";
import classes from "./WAButton.module.css";
import WAIcon from "../../assets/images/Whatsapp.svg";
import { Link } from "react-router-dom";

const WAButton = () => {
  return (
    <button className={classes.root}>
      <Link
        to="https://wa.me/918889000174?text=Hey there! I am excited to discover the educational opportunities Abroad."
        target="_blank"
        rel="noreferrer"
      >
        <img src={WAIcon} alt="" className={classes.WAIcon} />
      </Link>
    </button>
  );
};

export default WAButton;
