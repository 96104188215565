const visaInfoData =[
    {
        id:1,
        url:'https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/usa-flag.png?updatedAt=1697286621443',
        text:"USA: F-1 Visa (Academic Student)",
        country:'USA'
    },
    {
        id:2,
        url:'https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/germany-flag.png?updatedAt=1697286621439',
        text:"Germany: Student Visa (National Visa - Category D)",
        country:'Germany'
    },
    {
        id:3,
        url:'https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/uk-flag.png?updatedAt=1697286621278',
        text:"UK: Tier 4 (General) Student Visa",
        country:'UK'
    },
    {
        id:4,
        url:'https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/australia-flag.png?updatedAt=1697286621105',
        text:"Australia: Student Visa (Subclass 500)",
        country:'Australia'
    },
    {
        id:5,
        url:'https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/ireland-flag.png?updatedAt=1697286621279',
        text:"Ireland: Student Visa (D Study Visa)",
        country:'Ireland'
    },
]

export default visaInfoData;