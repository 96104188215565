import React from 'react'
import HomeSection from './homeSection';
import ProcessSection from './processSection';
import DashboardSection from './dashboardSection';
import CountrySection from './countrySection';
import CoursesGridSection from './coursesGridSection';
import TestimonialSection from './testimonialSection';
import { Helmet } from 'react-helmet';

export default function homePage() {
  return (
    <div>
      <Helmet>
        <title>Home | SkyDoor EdTech International</title>
        <meta name="description" content="Your ultimate study abroad partner! Top-notch study abroad consultants, best IELTS classes, and expert student visa services. Your success, our commitment!" />
        <meta name="keywords" content="Overseas education consultants, study abroad, overseas education, study abroad consultants, IELTS coaching, study abroad consultants in Indore, abroad education consultants in Indore" />
        <meta name="author" content="SkyDoor" />
        <meta name="author" content="Priyanshi Agrawal" />
        <meta property="og:title" content="Home | SkyDoor EdTech International" />
        <meta property="og:description" content="Your ultimate study abroad partner! Top-notch study abroad consultants, best IELTS classes, and expert student visa services. Your success, our commitment!" />
        <meta property="og:image" content="../assets/images/brand-logo.png" />
        <meta property="og:url" content="https://skydooredtech.in/" />
      </Helmet>
    <HomeSection />
    <ProcessSection />
    <DashboardSection />
    <CountrySection />
    <CoursesGridSection />
    <TestimonialSection />
    </div>
  )
}
