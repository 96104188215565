// Word limit : 70 per user
const testimonialData=[
    {
        id:1,
        name: "Nisha Udasi",
        university: "Symbiosis International University",
        testimonial: "SKYDOOR provided me with the best Assistance for the foreign Education.  The Team was very Helpful and guided me so well.For the IELTS classes  the faculty is really good. They covered parts of speech & tenses and grammar things which was very useful.Kudos Team SKYDOOR"
    },
    {
        id:2,
        name: "Abhinav Agrawal",
        university: "Dr. A.P.J. Abdul Kalam University",
        testimonial: "Skip fancy consultancies; trust Skydoor Edtech. My experience with Deepali, Shivani, and Prateek was amazing. They're friendly and experts in overseas matters. Prateek's visa interview skills are top-notch. You can trust Skydoor; they work tirelessly for your visa. Services are reasonable. Your application is safe with them. Don't wait; choose Skydoor."
    },
    {
        id:3,
        name: "Satyam Rai",
        university: "IIT Roorkee",
        testimonial: "This Indore-based foreign education consulting group excels due to its dedicated team, extensive resources, and unwavering support. They go the extra mile, ensuring candidates succeed in challenging circumstances. Their commitment and capabilities shine, making them the top choice for pursuing education abroad. Highly recommended for effective support in achieving educational goals."
    },
    {
        id:4,
        name: "Harshita Sharma",
        university: "medi-caps university",
        testimonial: "Skydoor is perfect to start an international career, detailed counseling by an international education professional helps improve decisions. All the services provided by skydoor are remarkable and this is the best offline IELTS preparation class in Indore"
    },
    {
        id:5,
        name: "Shreyash Mehta",
        university: "Shri Vaishnav Vidyapeeth Vishwavidyalaya",
        testimonial: "My time at SkyDoor EdTech has been absolutely amazing! They helped me with all of my questions about studying overseas. Great team supporting you through the application process at every stage.I would wholeheartedly advise everyone who is considering studying abroad to use SkyDoor because it was such a helpful and fulfilling experience."
    },
]

export default testimonialData;