import React from "react";
import { useNavigate } from "react-router-dom"; // used for enquiry form button redirect
import { Helmet } from "react-helmet";
// this is the "application" page code

const ApplicationsPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Application | SkyDoor EdTech International</title>
        <meta
          name="description"
          content="Let our adept team at Skydoor handle the intricacies of your application, ensuring you a seamless application process with a 100 % success rate."
        />
        <meta
          name="keywords"
          content="Study abroad application process, abroad education application process, SOP, statement of purpose, letter of recommendation, study abroad documentation"
        />
        <meta name="author" content="SkyDoor" />
        <meta name="author" content="Priyanshi Agrawal" />
        <meta
          property="og:title"
          content="Application | SkyDoor EdTech International"
        />
        <meta
          property="og:description"
          content="Let our adept team at Skydoor handle the intricacies of your application, ensuring you a seamless application process with a 100 % success rate."
        />
        <meta property="og:image" content="../assets/images/brand-logo.png" />
        <meta
          property="og:url"
          content="https://skydooredtech.in/applications"
        />
      </Helmet>

      <div className="application-container container-fluid padding-x margin-top d-flex flex-column row-gap-5">
        {/* university shortlisting */}
        <div className="row application-row padding-yt row-gap-3 align-items-center">
          <div className="col-lg-6 col-12 text-center text-lg-start">
            <h1 className="heading">Shortlisting</h1>
            <p>
              We go beyond generic recommendations, conducting in-depth research
              to identify institutions that complement your academic and career
              goals. SkyDoor deals with globally renowned institutions,
              including <b>Ivy League universities</b> ,{" "}
              <b>Germany's esteemed public universities</b> , and members of the{" "}
              <b>prestigious Russel Group</b> . Our guidance is not
              one-size-fits-all. We consider your unique profile to recommend
              universities that resonate with your academic strengths and
              ambitions.
            </p>
          </div>
          <div className="col-lg-6 col-12 text-center text-lg-end">
            <img
              src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/university-shortlisting.svg?updatedAt=1695458316344"
              alt="university shortlisting"
            />
          </div>
        </div>

        {/* documentation */}
        <div className="row application-row padding-yt row-gap-3 align-items-center flex-row flex-lg-row-reverse">
          <div className="col-lg-6 col-12 text-center text-lg-start">
            <h1 className="heading">Documentation</h1>
            <p>
              Let our adept content writing team at SkyDoor handle the
              intricacies of your documents. We craft every piece meticulously,
              aligning with specific university requirements. Additionally, our
              experts follow embassy procedures diligently, ensuring your visa
              documentation is precise and ready for success. Your academic
              journey deserves the attention to detail that SkyDoor provides.
            </p>
            <h5 className="text-red fw-bold">Our Services Includes :</h5>
          </div>
          <div className="col-lg-6 col-12 text-center text-lg-start">
            <img
              src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/documentation.svg?updatedAt=1695462193788"
              alt="university shortlisting"
            />
          </div>
        </div>

        {/* documentation services */}
        <div className="application-dropdown-container">
          <div className="application-dropdown-menu">
            <div className="row">
              {/* SOP */}
              <div className="col-lg-6 col-12 application-dropdown-item">
                <h3 className="text-yellow">SOP</h3>
                <p>
                  Your journey to admission and scholarships hinges on the
                  strength of your SOP (Statement of Purpose). At SkyDoor, we
                  recognize the pivotal role of this document, also known as a
                  letter of motivation or personal essay.
                </p>
              </div>

              {/* LOR */}
              <div className="col-lg-6 col-12 application-dropdown-item">
                <h3 className="text-blue">LOR</h3>
                <p>
                  For a comprehensive application, both academic and
                  professional Letters of Recommendation (LOR) are essential.
                  SkyDoor ensures your endorsements stand out, enhancing your
                  credibility and boosting your chances of success in the
                  application process.
                </p>
              </div>
            </div>
            <div className="row">
              {/* Resume */}
              <div className="col-lg-6 col-12 application-dropdown-item">
                <h3 className="text-red">Resume</h3>
                <p>
                  Your resume is not just a document; it's a snapshot of your
                  achievements. We understand that it is your visual narrative—a
                  key element in shaping your academic journey.
                </p>
              </div>

              {/* Essay */}
              <div className="col-lg-6 col-12 application-dropdown-item">
                <h3 className="text-green">Essay</h3>
                <p>
                  Certain top-tier universities recognize the power of your
                  unique voice. At SkyDoor, we acknowledge that some
                  institutions require essays for a deeper understanding of your
                  aspirations. Let us help you articulate your story with
                  authenticity and impact.
                </p>
              </div>
            </div>

            {/* CTA - Button */}
            <div className="text-center mt-3">
              <button
                className="btn btn-outline btn-round"
                onClick={() => navigate("/contactus")}
              >
                Book a Counselling Session
              </button>
            </div>
          </div>
        </div>

        {/* scholorship */}
        <div className="row application-row padding-yt row-gap-3 align-items-center">
          <div className="col-lg-6 col-12 text-center text-lg-start">
            <h1 className="heading">Scholarship</h1>
            <p>
              Unlock opportunities with our dedicated scholarship assistance. We
              guide you through identifying and applying for scholarships,
              ensuring that financial constraints don't hinder your pursuit of
              quality education.
            </p>
            <h5 className="text-green">
              Success ratio of scholarship applications
            </h5>

            {/* progress bar 1 */}
            <div>
              <span>2022</span>
              <div
                className="progress"
                role="progressbar"
                aria-label="Success example"
                aria-valuenow={25}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                <div className="progress-bar" style={{ width: "80%" }}>
                  80%
                </div>
              </div>
            </div>

            {/* progress bar 2 */}
            <div className="mt-4">
              <span>2023</span>
              <div
                className="progress"
                role="progressbar"
                aria-label="Success example"
                aria-valuenow={25}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                <div className="progress-bar" style={{ width: "85%" }}>
                  85%
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-12 text-center text-lg-end">
            <img
              src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/scholorship.svg?updatedAt=1695462193766"
              alt="university shortlisting"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplicationsPage;
