import React from 'react'
import { Helmet } from 'react-helmet'
// this is a "about us" page code
const AboutPage = () => {
  return (
    <>
     <Helmet>
        <title>About Us | SkyDoor EdTech International</title>
        <meta name="description" content="Founded in 2022, SkyDoor transcends the role of a mere consultancy firm; it stands as a guiding light for transformative education." />
        <meta name="keywords" content="About skydoor,  who we are, skydoor services, abroad education consultants, skydoor consultancy services" />
        <meta name="author" content="SkyDoor" />
        <meta name="author" content="Priyanshi Agrawal" />
        <meta property="og:title" content="About Us | SkyDoor EdTech International" />
        <meta property="og:description" content="Founded in 2022, SkyDoor transcends the role of a mere consultancy firm; it stands as a guiding light for transformative education." />
        <meta property="og:image" content="../assets/images/brand-logo.png" />
        <meta property="og:url" content="https://skydooredtech.in/about" />
      </Helmet>
     <div className='about-container wave-bg container-fluid padding-x margin-top text-center'>
      {/* heading - about us */}
      <h1 className="heading padding-yt mb-4">Who <span className="highlight">We Are</span></h1>
      {/* intro of about us */}
      <dl className='about-text'>
        <dd>Founded in 2022, SkyDoor transcends the role of a mere consultancy firm; it stands as a guiding light for transformative education. Nestled in the lively city of Indore, Madhya Pradesh, we are a registered partnership firm with a singular dedication to reshaping the educational landscape on a global scale.</dd>
        <dd>At the core of SkyDoor lies a clear vision—to revolutionize the education system. Our unwavering commitment is directed towards guiding and supporting students in their pursuit of career aspirations. SkyDoor's impact spans the globe, serving clients from diverse corners, overcoming geographical boundaries to provide exceptional educational guidance.</dd>
        <dd>Recognizing the challenges students encounter on the intricate path to higher education, we've undertaken a mission. SkyDoor is not just a solution; it's a dynamic force tailored to address the unique needs of each student. Specializing in Abroad Education Consultation, we take immense pride in our reputation for excellence.</dd>
      </dl>

      {/* core principles section starts */}
      <h4 className='text-start'><b>Core Principles That Define Us:</b></h4>
      <div className="row text-start">
        {/* integrity */}
        <div className="about-icon-card col-lg-3 col-md-6 col-12 d-flex flex-column row-gap-2 mt-2">
          <div className="about-icon">
            <svg xmlns="http://www.w3.org/2000/svg" height="2rem" viewBox="0 0 576 512">
              <path d="M7.8 207.7c-13.1-17.8-9.3-42.8 8.5-55.9L142.9 58.5C166.2 41.3 194.5 32 223.5 32H384 544c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H507.2l-44.9 36c-22.7 18.2-50.9 28-80 28H304 288 224c-17.7 0-32-14.3-32-32s14.3-32 32-32h64 16c8.8 0 16-7.2 16-16s-7.2-16-16-16H183.4L63.7 216.2c-17.8 13.1-42.8 9.3-55.9-8.5zM382.4 160l0 0 .9 0c-.3 0-.6 0-.9 0zM568.2 304.3c13.1 17.8 9.3 42.8-8.5 55.9L433.1 453.5c-23.4 17.2-51.6 26.5-80.7 26.5H192 32c-17.7 0-32-14.3-32-32V384c0-17.7 14.3-32 32-32H68.8l44.9-36c22.7-18.2 50.9-28 80-28H272h16 64c17.7 0 32 14.3 32 32s-14.3 32-32 32H288 272c-8.8 0-16 7.2-16 16s7.2 16 16 16H392.6l119.7-88.2c17.8-13.1 42.8-9.3 55.9 8.5zM193.6 352l0 0-.9 0c.3 0 .6 0 .9 0z" />
            </svg>
          </div>
          <h4 className="about-icon-title">Integrity</h4>
          <p className="about-icon-text">Trust and transparency are the bedrock of our services. At SkyDoor, we operate with unwavering honesty, ensuring that our guidance is built on ethical practices.</p>
        </div>

        {/* Quality-Based Service */}
        <div className="about-icon-card col-lg-3 col-md-6 col-12 d-flex flex-column row-gap-2 mt-2">
          <div className="about-icon">
            <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 0 640 512">
              <path d="M353.8 54.1L330.2 6.3c-3.9-8.3-16.1-8.6-20.4 0L286.2 54.1l-52.3 7.5c-9.3 1.4-13.3 12.9-6.4 19.8l38 37-9 52.1c-1.4 9.3 8.2 16.5 16.8 12.2l46.9-24.8 46.6 24.4c8.6 4.3 18.3-2.9 16.8-12.2l-9-52.1 38-36.6c6.8-6.8 2.9-18.3-6.4-19.8l-52.3-7.5zM256 256c-17.7 0-32 14.3-32 32V480c0 17.7 14.3 32 32 32H384c17.7 0 32-14.3 32-32V288c0-17.7-14.3-32-32-32H256zM32 320c-17.7 0-32 14.3-32 32V480c0 17.7 14.3 32 32 32H160c17.7 0 32-14.3 32-32V352c0-17.7-14.3-32-32-32H32zm416 96v64c0 17.7 14.3 32 32 32H608c17.7 0 32-14.3 32-32V416c0-17.7-14.3-32-32-32H480c-17.7 0-32 14.3-32 32z" />
            </svg>

          </div>
          <h4 className="about-icon-title">Quality-Based Service</h4>
          <p className="about-icon-text">Education is an investment in the future. Our dedicated team tirelessly provides top-notch services, ensuring students embark on a successful educational journey.</p>
        </div>

        {/* On-time Delivery */}
        <div className="about-icon-card col-lg-3 col-md-6 col-12 d-flex flex-column row-gap-2 mt-2">
          <div className="about-icon">
            <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 0 448 512">
              <path d="M176 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h16V98.4C92.3 113.8 16 200 16 304c0 114.9 93.1 208 208 208s208-93.1 208-208c0-41.8-12.3-80.7-33.5-113.2l24.1-24.1c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L355.7 143c-28.1-23-62.2-38.8-99.7-44.6V64h16c17.7 0 32-14.3 32-32s-14.3-32-32-32H224 176zm72 192V320c0 13.3-10.7 24-24 24s-24-10.7-24-24V192c0-13.3 10.7-24 24-24s24 10.7 24 24z" />
            </svg>
          </div>
          <h4 className="about-icon-title">On-time Delivery</h4>
          <p className="about-icon-text">Recognizing the importance of deadlines in education, SkyDoor is committed to delivering timely and efficient services. We ensure students are well-prepared for academic pursuits abroad.</p>
        </div>

        {/* Client Support */}
        <div className="about-icon-card col-lg-3 col-md-6 col-12 d-flex flex-column row-gap-2 mt-2">
          <div className="about-icon">
            <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 0 512 512">
              <path d="M256 48C141.1 48 48 141.1 48 256v40c0 13.3-10.7 24-24 24s-24-10.7-24-24V256C0 114.6 114.6 0 256 0S512 114.6 512 256V400.1c0 48.6-39.4 88-88.1 88L313.6 488c-8.3 14.3-23.8 24-41.6 24H240c-26.5 0-48-21.5-48-48s21.5-48 48-48h32c17.8 0 33.3 9.7 41.6 24l110.4 .1c22.1 0 40-17.9 40-40V256c0-114.9-93.1-208-208-208zM144 208h16c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H144c-35.3 0-64-28.7-64-64V272c0-35.3 28.7-64 64-64zm224 0c35.3 0 64 28.7 64 64v48c0 35.3-28.7 64-64 64H352c-17.7 0-32-14.3-32-32V240c0-17.7 14.3-32 32-32h16z" />
            </svg>

          </div>
          <h4 className="about-icon-title">Client Support</h4>
          <p className="about-icon-text">Your journey is our priority. With personalized guidance and a commitment to addressing your concerns, we're here to support you every step of the way. Your success is our success.</p>
        </div>
      </div>

      {/* our gallery section */}
      <h1 className="heading padding-yt">Our <span className="highlight">Gallery</span></h1>
      <div className="about-grid-container">
        
        {/* item1 */}
      <div className="about-grid-item item1">
        <img src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/reception.jpg?updatedAt=1697541221929" alt="reception area" />
      </div>

      {/* item2 */}
      <div className="about-grid-item item2">
        <img src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/front-door.jpg?updatedAt=1697541245109" alt="frontdoor" />
      </div>

      {/* item3 */}
      <div className="about-grid-item item3" >
        <img src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/counselling-room.jpg?updatedAt=1697542039544" alt="counselling room" />
      </div>

      {/* item4 */}
      <div className="about-grid-item item4">
        <img src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/IMG20231017172946.jpg?updatedAt=1697544405330" alt="classroom" />
      </div>

      {/* item5 */}
      <div className="about-grid-item item5">
        <img src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/IMG20231014173802.jpg?updatedAt=1697538770783" alt="working area" />
      </div>
      </div>

    </div>
    </>
   
  )
}

export default AboutPage