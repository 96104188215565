import React from "react";
import classes from "./TrainingCard.module.css";

const TrainingCard = ({ title, list, headerColor, color }) => {
  return (
    <div className={`card ${classes.trainingCard}`}>
      <div
        className={`card-header heading ${classes.trainingCardHeader}`}
        style={{
          background: `${headerColor}`,
        }}
      >
        <span className={color}>
        {title}
        </span>
      </div>
      <div className="card-body">
        {list.map((item) => (
          <div key={item} className="d-flex gap-2 gap-md-2">
            <div className="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="20"
                width="20"
                viewBox="0 0 512 512"
              >
                <path
                  fill="#41b93c"
                  d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"
                />
              </svg>
            </div>
            <p className="text-start">{item}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrainingCard;
