const coursesGridData = [
    {
        id:1,
        title:"psychology",
        url:"https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/course-psychology.png?updatedAt=1692687993151",
        university:[
            'Harvard University', 'University of Oxford'
        ],
        color:'#f7f7f7'
    },
    {
        id:2,
        title:"physics",
        url:"https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/course-physics.png?updatedAt=1692687992943",
        university:[
            'MIT', 'Harvard University'
        ],
        color:'#f3fafc'
    },
    {
        id:3,
        title:"game Development",
        url:"https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/course-gamedev.png?updatedAt=1692687993142",
        university:[
            'Stanford University', 'University of Washington'
        ],
        color:'#f3fafc'
    },
    {
        id:4,
        title:"Artificial Intelligence",
        url:"https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/course-ai.png?updatedAt=1692687992935",
        university:[
            'Stanford University', 'University of California'
        ],
        color:'#f7f7f7'
    },
    {
        id:5,
        title:"Law",
        url:"https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/course-law.png?updatedAt=1692687992865",
        university:[
            'Harvard University', 'University of Oxford'
        ],
        color:'#f7f7f7'
    },
    {
        id:6,
        title:"Techno-Management",
        url:"https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/course-technomanagement.png?updatedAt=1692687993344",
        university:[
            'MIT', 'University of Cambridge'
        ],
        color:'#fffaf5'
    },
    {
        id:7,
        title:"Agriculture",
        url:"https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/course-agriculture.svg?updatedAt=1694262102623",
        university:[
            'Wageningen University & Research', 'University of California'
        ],
        color:'#fffaf5'
    },
    {
        id:8,
        title:"Forensic Science",
        url:"https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/course-forensicscience.png?updatedAt=1692687993102",
        university:[
            'University of Leicester', 'Michigan State University'
        ],
        color:'#f7f7f7'
    },
    {
        id:9,
        title:"Finance & Accounting",
        url:"https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/course-finance.png?updatedAt=1692687993051",
        university:[
            'Harvard University', 'MIT'
        ],
        color:'#f7f7f7'
    },
    {
        id:10,
        title:"Cybersecurity & Ethical Hacking",
        url:"https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/course-cyber-security.svg?updatedAt=1694260917924",
        university:[
            'University of California', 'Stanford University'
        ],
        color:'#f3fafc'
    },
    {
        id:11,
        title:"Fashion Designing",
        url:"https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/course-fashion-design.svg?updatedAt=1694261067642",
        university:[
            'Stanford University', 'University of Illinois'
        ],
        color:'#f3fafc'
    },
    {
        id:12,
        title:"Hospitality & Tourism",
        url:"https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/course-hospitality.svg?updatedAt=1694261551674",
        university:[
            'EHL Hospitality Business School', 'SHMS'
        ],
        color:'#f7f7f7'
    },
];

export default coursesGridData;
