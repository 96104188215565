import React from 'react'

const Event = () => {
    return (
        <div className='event-slider'>
            <button
                className="btn event-btn btn-hover fw-bold"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseWidthExample"
                aria-expanded="false"
                aria-controls="collapseWidthExample"
            >
                FREE IELTS MOCK TEST
            </button>
            <div style={{ minHeight: 120 }}>
                <div className="collapse collapse-horizontal" id="collapseWidthExample">
                    <div className="card card-body" style={{ width: 250 }}>
                        <h6 className='text-green ms-2'>Type “Free IELTS Test” and send it to WhatsApp.</h6>
                        <div className="event-info d-flex justify-content-between">
                            {/* date */}
                            {/* <div className="event-info-item d-flex align-items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" height="12px" viewBox="0 0 448 512">
                                    <path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192H400V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192z" />
                                </svg>
                                <span className='px-1'>07-10-2023</span>
                            </div> */}
                            {/* time */}
                            {/* <div className="event-info-item d-flex align-items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" height="12px" viewBox="0 0 512 512">
                                    <path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
                                </svg>

                                <span className='px-1'>06:30 PM Onwards</span>
                            </div> */}
                        </div>
                        <button className="btn btn-round btn-outline btn-hover mt-2" onClick={() => window.open("https://wa.me/918889000174?text=Hey! I want to get a free IELTS Test by SkyDoor!", '_blank')} >SEND</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Event