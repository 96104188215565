import React from 'react'
import CountUp from 'react-countup';
import dashboardData from '../assets/data/dashboardData';

export default function dashboardSection() {
  return (
    <div className='dashboard container-fluid padding-xy text-center'>
      <h1 className='heading'>Accomplishments  <span className='highlight'>Tracker</span> </h1>
      <div className="dashboard-card-container padding-yt">

        {/* dashboard card 1 */}
        {dashboardData.map((data) => (
          <div className="dashboard-card" key={data.id}>
          <div className="dashboard-icon">
            {data.svg}
          </div>
          <h1 className="counter heading"><CountUp end={data.counter} enableScrollSpy /> {data.counterNext}</h1>
          <p className="counter-info">{data.counterInfo}</p>
        </div>
        ))}

      </div>
    </div>
  )
}
