import React from "react";
import { NavLink, useNavigate } from "react-router-dom";

export default function Navbar() {
  const navigate = useNavigate();
  return (
    <div className="nav-main fixed-top  padding-x">
      {/* social icons */}
      <div className="nav-top d-flex justify-content-end py-1">
        <a
          href="https://wa.me/918889000174?text=Hey there! I am excited to discover the educational opportunities Abroad."
          className="px-1"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/whatsaap-icon.svg?updatedAt=1696853805393"
            alt="whatsaap icon"
          />
        </a>

        <a
          href="https://www.linkedin.com/company/skydooredtech/"
          className="px-1"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/linkedin_icon.png?updatedAt=1693048244693"
            alt="linkedin icon"
          />
        </a>

        <a
          href="https://www.instagram.com/skydooredtech/?hl=en"
          className="px-1"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/instagram_icon.png?updatedAt=1693048312497"
            alt="instagram icon"
          />
        </a>

        <a
          href="https://www.facebook.com/skydooredtech"
          className="px-1"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/facebook_icon.png?updatedAt=1693048244581"
            alt="facebook icon"
          />
        </a>

        <a
          href="https://g.page/r/CbAi6G76N4olEBM/"
          className="px-1"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/google-icon.png?updatedAt=1696853339015"
            alt="google page icon"
          />
        </a>
      </div>
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <img
            className="navbar-brand"
            src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/brand-logo.png?updatedAt=1692686281099"
            alt="brand-logo"
            onClick={() => navigate("/")}
          />
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item dropdown">
                <NavLink
                  className="nav-link dropdown-toggle"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Services
                </NavLink>
                <ul className="dropdown-menu">
                  <li>
                    <NavLink className="dropdown-item" to="/exams">
                      Exams
                    </NavLink>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/applications">
                      Applications
                    </NavLink>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/visa">
                      Visa
                    </NavLink>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <NavLink
                      className="dropdown-item"
                      to="/permanent-residence"
                    >
                      PR
                    </NavLink>
                  </li>
                </ul>
              </li>
              {/* <li className="nav-item">
                <NavLink to="/team" className="nav-link" aria-current="page">
                  Team
                </NavLink>
              </li> */}
              <li className="nav-item">
                <NavLink to="/about" className="nav-link">
                  About Us
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/results" className="nav-link">
                  Results
                </NavLink>
              </li>
            </ul>
            <div>
              <button
                className="btn btn-round nav-btn btn-hover"
                onClick={() => navigate("/contactus")}
              >
                Start Evaluation
              </button>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
