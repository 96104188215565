import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap";
import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import ScrollToTop from "./components/scrollToTop";
import Navbar from "./components/navbar";
import HomePage from "./pages/homePage";
// import TeamPage from "./pages/teamPage";
import ResultPage from "./pages/resultPage";
import EnquiryFormPage from "./pages/enquiryFormPage";
import ExamsPage from "./pages/examsPage";
import ApplicationsPage from "./pages/applicationsPage";
import VisaPage from "./pages/visaPage";
import AboutPage from "./pages/aboutPage";
import PermanentResidencePage from "./pages/permanentResidencePage";
import Footer from "./components/footer";
import Event from './components/event';
import NavbarSEO from "./components/NavbarSEO";
// import PageUnderConstruction from "./components/pageUnderConstruction";
import IeltsIndore from "./pages/SEO/IeltsIndore";
import OverseasEducation from "./pages/SEO/OverseasEducation";
import AbroadMP from "./pages/SEO/AbroadMP";
import WAButton from "./components/WAButton/WAButton";
// import PopUpCard from './components/popUpCard';

const seoRoutes = [
  "/ielts-coaching-in-indore",
  "/overseas-education-consultant/indore",
  "/study-abroad-consultants/madhyapradesh",
];

function App() {
  const location = useLocation();
  return (
    <div className="App position-relative">
      {seoRoutes.includes(location.pathname) ? <NavbarSEO /> : <Navbar />}
      <WAButton />
      <Event />
      {/* <PopUpCard /> */}
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route
          exact
          path="/ielts-coaching-in-indore"
          element={<IeltsIndore />}
        />
        <Route
          exact
          path="/overseas-education-consultant/indore"
          element={<OverseasEducation />}
        />
        <Route
          exact
          path="/study-abroad-consultants/madhyapradesh"
          element={<AbroadMP />}
        />
        {/* <Route exact path="/team" element={<TeamPage />} /> */}
        {/* <Route exact path="/team" element={<PageUnderConstruction />} /> */}
        <Route exact path="/about" element={<AboutPage />} />
        <Route exact path="/results" element={<ResultPage />} />
        {/* <Route exact path="/results" element={<PageUnderConstruction />} /> */}
        <Route exact path="/contactus" element={<EnquiryFormPage />} />
        <Route exact path="/exams" element={<ExamsPage />} />
        <Route exact path="/applications" element={<ApplicationsPage />} />
        <Route exact path="/visa" element={<VisaPage />} />
        <Route
          exact
          path="/permanent-residence"
          element={<PermanentResidencePage />}
        />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
