import React from 'react'
import { useNavigate } from "react-router-dom";

export default function Footer() {
    const navigate = useNavigate();
    return (
        <footer className='footer-section'>
            <div className="footer container-fluid padding-xy">
                <div className="footer-left">
                    <img src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/brand-logo.png?updatedAt=1692686281099" alt="logo" />
                    <div className="footer-contact">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" /></svg>
                        <a href="mailto:enquiry@skydooredtech.in" target='_blank' rel="noreferrer">enquiry@skydooredtech.in</a>
                    </div>
                    <div className="footer-contact">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" /></svg>
                        <a href="tel:+918889000174" target='_blank' rel='noreferrer'>+91-8889000174</a>
                    </div>

                    {/* cta btn */}
                    <button className="btn btn-round nav-btn btn-hover" onClick={() => navigate("/contactus")} >
                        Book A Counselling Session
                    </button>

                    {/* social icons */}
                    <div className="footer-icon-container">
                    <a href="https://wa.me/918889000174?text=Hey there! I am excited to discover the educational opportunities Abroad." className='px-1' target='_blank' rel="noreferrer"><img src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/whatsaap-icon.svg?updatedAt=1696853805393" alt="whatsaap icon" /></a>

                    <a href="https://www.linkedin.com/company/skydooredtech/" className='px-1' target='_blank' rel="noreferrer"><img src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/linkedin_icon.png?updatedAt=1693048244693" alt="linkedin icon" /></a>

                    <a href="https://www.instagram.com/skydooredtech/?hl=en" className='px-1' target='_blank' rel="noreferrer"><img src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/instagram_icon.png?updatedAt=1693048312497" alt="instagram icon" /></a>

                    <a href="https://www.facebook.com/skydooredtech" className='px-1' target='_blank' rel="noreferrer"><img src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/facebook_icon.png?updatedAt=1693048244581" alt="facebook icon" /></a>

                    <a href="https://g.page/r/CbAi6G76N4olEBM/" className='px-1' target='_blank' rel="noreferrer"><img src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/google-icon.png?updatedAt=1696853339015" alt="google page icon" /></a>
                </div>
                </div>

                {/* footer middle - links */}
                <div className="footer-middle">
                    <div className="row">
                        <div className='col-lg-7 col-md-12'>
                            <h5 className="footer-title text-red">Services</h5>
                            <p className="footer-item" onClick={() => navigate("/applications")}>Applications</p>
                            <p className="footer-item" onClick={()=>navigate("/visa")}>Visa</p>
                            <p className="footer-item" onClick={()=>navigate("/exams")}>Exam</p>
                            <p className="footer-item" onClick={()=>navigate("/permanent-residence")}>PR</p>
                        </div>
                        <div className='col-lg-5 col-md-12'>
                            <h5 className="footer-title text-green">achievments</h5>
                            <p className="footer-item" onClick={()=>navigate("/results")}>IELTS result</p>
                            <p className="footer-item" onClick={()=>navigate("/results")}>visa report</p>
                            <p className="footer-item" onClick={()=>navigate("/results")}>GRE result</p>
                        </div>

                    </div>
                    <div className="row">
                        {/* <div className='col-lg-7 col-md-12'>
                            <h5 className="footer-title text-yellow">Our Team</h5>
                            <p className="footer-item" onClick={()=>navigate("/team")}>team</p>
                            <p className="footer-item" onClick={()=>navigate("/team")}>careers</p>
                        </div> */}
                        <div className='col-lg-5 col-md-12'>
                            <h5 className="footer-title text-blue">About Us</h5>
                            <p className="footer-item" onClick={()=>navigate("/about")}>Who we are</p>
                        </div>
                    </div>
                </div>

                {/* footer right - map */}
                <div className="footer-right">
                    <iframe title='map'
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3680.1482115127046!2d75.88449607489451!3d22.722731927451928!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962e31193cdbcd3%3A0x258a37fa6ee822b0!2sSkyDoor%20EdTech%20International!5e0!3m2!1sen!2sin!4v1693045028179!5m2!1sen!2sin"
                        width={350}
                        height={200}
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    />
                    <h5>M-08 Shekhar Central Palasia Square AB Road Indore Madhya Pradesh 452001</h5>
                </div>
            </div>
            <div className="secondary-footer container-fluid text-center">
                <p>©copyright reserved 2023 </p>
            </div>
        </footer>
    )
}
