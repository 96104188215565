import React from 'react'
import { Helmet } from 'react-helmet'
const PermanentResidencePage = () => {
  return (
    <>
    <Helmet>
        <title>Permanent Residence | SkyDoor EdTech International</title>
        <meta name="description" content="Our expert team is dedicated to steering you towards a successful PR journey, turning your dream of Canada/ Australia residency into reality." />
        <meta name="keywords" content="PR consultants, Canada permanent residency, Australia permanent residency, Canada-PR, Australia-PR, best PR consultants in Indore, permanent residency programs, PR visa consultants in Indore" />
        <meta name="author" content="SkyDoor" />
        <meta name="author" content="Priyanshi Agrawal" />
        <meta property="og:title" content="Permanent Residence  | SkyDoor EdTech International" />
        <meta property="og:description" content="Our expert team is dedicated to steering you towards a successful PR journey, turning your dream of Canada/ Australia residency into reality." />
        <meta property="og:image" content="../assets/images/brand-logo.png" />
        <meta property="og:url" content="https://skydooredtech.in/permanent-residence" />
      </Helmet>
     <div className='residence-container container-fluid padding-x margin-top'>
      {/* heading - PR */}
      <h1 className="heading padding-x padding-yt text-center">Permanent <span className="highlight">Residence</span></h1>

      {/* canada */}
      <div className="row">
        <div className="col-lg-5 col-12 d-flex flex-column justify-content-center">
          <h1>Canada</h1>
          <p>Unlock the door to endless opportunities with Canada Permanent Residency. Embrace a thriving multicultural society, top-notch healthcare, and a robust economy. Let us guide you through a seamless immigration process, turning your dream of Canadian residency into a reality.</p>
        </div>
        <div className="col-lg-7 col-12 text-lg-end">
          <img src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/canada-flag.png?updatedAt=1697545340961" alt="canada flag" />
        </div>
      </div>

      {/* australia */}
      <div className="row flex-row-reverse mt-4 mt-lg-0">
        <div className="col-lg-5 col-12 d-flex flex-column justify-content-center">
          <h1>Australia</h1>
          <p>Elevate your lifestyle with Australia Permanent Residency. Immerse yourself in the Aussie way of life, where stunning landscapes meet unparalleled career prospects. Our expert team is dedicated to steering you towards a successful PR journey, ensuring you experience the best that Australia has to offer. Start your new chapter down under today!</p>
        </div>
        <div className="col-lg-7 col-12 mb-4">
          <img src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/australia-flag.png?updatedAt=1697286621105" alt="australia flag" />
        </div>
      </div>
    </div>
    </>
   
  )
}

export default PermanentResidencePage