import React from 'react'
import coursesGridData from '../assets/data/coursesGridData'  // importing the course grid data from the array file

// this is the "course grid" section of "home" page

export default function coursesGridSection() {
    return (
        <div className='course-grid-section container-fluid padding-xy text-center'>
            <h1 className="heading">
                Opt for <span className="highlight"> Desired Program</span>
            </h1>

            {/* mapping the data from array */}
            <div className="course-grid padding-yt">
                <div className="course-grid-container">
                    {coursesGridData.map((data) => (

                        // course item : x
                        <div className="course-grid-card" key={data.id}>
                            <div className="course-card-inner">
                                <div className="course-card-front" style={{ background: data.color }}>
                                    <h4>{data.title}</h4>
                                    <img src={data.url} alt={data.title} />
                                </div>
                                <div className="course-card-back" style={{ background: data.color }}>
                                    <ul className="list-group">
                                        {data.university.map((clg, index) => (
                                    
                                    <li className="list-group-item" key={index}>{clg}</li>
                                
                                        ))}
                                    </ul>
                                </div>
                            </div>

                        </div>
                    ))}


                </div>
            </div>

        </div>
    )
}
