import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import resultData from "../assets/data/resultData";
import { useNavigate } from "react-router-dom"; // used for enquiry form button redirect
import { Helmet } from "react-helmet";

// rafce

const ResultPage = () => {
  const navigate = useNavigate(); // navigate
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filteredItems, setFilteredItems] = useState(resultData);

  let filters = ["exam", "admit", "visa"];

  const handleFilterButtonClick = (selectedCategory) => {
    if (selectedFilters.includes(selectedCategory)) {
      let filters = selectedFilters.filter((el) => el !== selectedCategory);
      setSelectedFilters(filters);
    } else {
      setSelectedFilters([...selectedFilters, selectedCategory]);
    }
  };

  useEffect(() => {
    filterItems();
  }, [selectedFilters]);

  const filterItems = () => {
    if (selectedFilters.length > 0) {
      let tempItems = selectedFilters.map((selectedCategory) => {
        let temp = resultData.filter(
          (item) => item.category === selectedCategory
        );
        return temp;
      });
      setFilteredItems(tempItems.flat());
    } else {
      setFilteredItems([...resultData]);
    }
  };

  return (
    <>
      <Helmet>
        <title>Results |IELTS | Admits | Visa</title>
        <meta
          name="description"
          content="Explore the remarkable achievements of our candidates. This special collection reflects the incredible journey and success of our dedication to excellence."
        />
        <meta
          name="keywords"
          content="Ielts results, Ielts scholars, SkyDoor’s Scholars, SkyDoor’s success, Skydoor achievements, skydoor top-admits, skydoor visa success, ielts success, ivy league admits, german-public admits"
        />
        <meta name="author" content="SkyDoor" />
        <meta name="author" content="Priyanshi Agrawal" />
        <meta property="og:title" content="Results |IELTS | Admits | Visa" />
        <meta
          property="og:description"
          content="Explore the remarkable achievements of our candidates. This special collection reflects the incredible journey and success of our dedication to excellence."
        />
        <meta property="og:image" content="../assets/images/brand-logo.png" />
        <meta property="og:url" content="https://skydooredtech.in/results" />
      </Helmet>
      <div className="result-container container-fluid padding-x margin-top">
        <div className="result-header">
          <div className="row">
            <div className="header-left col-md-5 col-12">
              <h1 className="heading">
                SkyDoor’s <span className="highlight">Scholars</span>{" "}
              </h1>
              <p className="sub-heading">
                Explore the remarkable achievements and important milestones
                accomplished by our candidates. This special collection reflects
                the incredible journeys and successes that highlight our
                dedication to excellence.
              </p>
              <button
                className="btn btn-round btn-outline btn-hover"
                onClick={() => navigate("/contactus")}
              >
                Book A Counselling Session
              </button>
            </div>
            <div className="header-right col-md-7 col-12">
              <img
                src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/result-header.svg?updatedAt=1693810007055"
                alt="result header image"
              />
            </div>
          </div>
        </div>

        <div className="result-display-container padding-yt">
          <div className="result-button-container">
            {filters.map((category, idx) => (
              <button
                onClick={() => handleFilterButtonClick(category)}
                className={`btn btn-outline btn-round ${
                  selectedFilters?.includes(category) ? "result-btn-active" : ""
                }`}
                key={`filters-${idx}`}
              >
                {category}
              </button>
            ))}
          </div>

          <div className="result-grid-container padding-yt">
            {filteredItems.map((item, idx) => (
              <div key={`items-${idx}`} className="result-item">
                <img src={item.url} alt={item.category} />
                <p className="category">{item.category}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ResultPage;

// export default function resultPage() {

//     const [selectedFilters, setSelectedFilters] = useState([]);
//     const [filteredItems, setFilteredItems] = useState(resultData);

//     let filters = ["exam", "admit", "visa"];

//     const handleFilterButtonClick = (selectedCategory) => {
//         if (selectedFilters.includes(selectedCategory)) {
//             let filters = selectedFilters.filter((el) => el !== selectedCategory);
//             setSelectedFilters(filters);
//         } else {
//             setSelectedFilters([...selectedFilters, selectedCategory]);
//         }
//     };

//     useEffect(() => {
//         filterItems();
//     }, [selectedFilters]);

//     const filterItems = () => {
//         if (selectedFilters.length > 0) {
//             let tempItems = selectedFilters.map((selectedCategory) => {
//                 let temp = resultData.filter((item) => item.category === selectedCategory);
//                 return temp;
//             });
//             setFilteredItems(tempItems.flat());
//         } else {
//             setFilteredItems([...resultData]);
//         }
//     };

//     return (
//         <div className='result-container container-fluid padding-x margin-top'>
//             <div className="result-header">
//                 <div className="row">
//                     <div className="result-header-left col-md-5 col-12">
//                         <h1 className='heading'>Our  Remarkable <span className='highlight'>Results & Achievement</span> </h1>
//                         <p className='sub-heading'>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Optio nam animi quod autem ullam veritatis incidunt, dignissimos unde laborum quibusdam?
//                         </p>
//                         <button className="btn btn-round btn-outline btn-hover" >Book A Counselling Session</button>
//                     </div>
//                     <div className="result-header-right col-md-7 col-12">
//                         <img src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/result-header.svg?updatedAt=1693810007055" alt="result header image" />
//                     </div>
//                 </div>
//             </div>

//             <div className="result-display-container padding-yt">

//             <div className="result-button-container">
//             {filters.map((category, idx) => (
//           <button
//             onClick={() => handleFilterButtonClick(category)}
//             className={`btn btn-outline ${
//               selectedFilters?.includes(category) ? "result-btn-active" : ""
//             }`}
//             key={`filters-${idx}`}
//           >
//             {category}
//           </button>
//         ))}
//             </div>

//             <div className="result-grid-container padding-yt">
//             {filteredItems.map((item, idx) => (
//           <div key={`items-${idx}`} className="result-item">
//             <img src={item.url} alt={item.category} />
//             <p className="category">{item.category}</p>
//           </div>
//         ))}
//             </div>
//             </div>
//         </div>
//     )
// }
