import React from 'react'
// import processData from '../assets/data/processData'

export default function processSection() {
    return (
        <div className='process-section container-fluid padding-xy text-center'>
            <h1 className="heading">Process <span className="highlight"> Outline
                </span></h1>
            <p className='sub-heading'>We streamline each stage for your convenience.
            </p>
            <img src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/process.svg?updatedAt=1696921320261" alt="process" />
            {/* <div className="process-container padding-yt">
                <div className="process-line"></div>
                <div className="process-card-container">
                    {processData.map((item) => (
                        <div className="process-card" key={item.id}>
                            <div className="process-icon">
                            <div className="process-icon-bg"></div>
                                 {item.svg}
                            </div>
                                <p className="process-name">
                                    {item.title}
                                </p>
                        </div>
                    ))}
                </div>
            </div> */}
            </div>
            )
}
