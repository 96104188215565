import React from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const ExamsPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Exams | SkyDoor EdTech International</title>
        <meta
          name="description"
          content="Confused about which test you prepare for your study abroad endeavor?  Let our expert team at Skydoor aid you in excelling in your exams."
        />
        <meta
          name="keywords"
          content="Abroad education exams, IELTS, GRE, GMAT, DUOLINGO, PTE, TOEFL, SAT, study abroad exams, English proficiency tests"
        />
        <meta name="author" content="SkyDoor" />
        <meta name="author" content="Priyanshi Agrawal" />
        <meta
          property="og:title"
          content="Exams | SkyDoor EdTech International"
        />
        <meta
          property="og:description"
          content="Confused about which test you prepare for your study abroad endeavor?  Let our expert team at Skydoor aid you in excelling in your exams."
        />
        <meta property="og:image" content="../assets/images/brand-logo.png" />
        <meta property="og:url" content="https://skydooredtech.in/exams" />
      </Helmet>
      <div className="exam-container container-fluid padding-x margin-top">
        <div className="row row-gap-5 padding-yt">
          <div className="col-lg-6 col-12 text-center text-lg-start">
            <h1 className="heading">
              English <span className="highlight">Proficiency Test</span>{" "}
            </h1>
            <p>
              In the realm of higher education applications, PR, and visa
              procedures, mastering the English Proficiency test is a
              fundamental requirement. At SkyDoor, we recognize the pivotal role
              this test plays in your academic and career journey. Our courses
              are meticulously crafted to serve a dual purpose—evaluating your
              calibre and providing targeted training through proven strategies.
              We understand that your success in English proficiency exams is
              not just a milestone; it's a gateway to broader opportunities.
            </p>
            <button
              className="btn btn-round btn-outline btn-hover"
              onClick={() => navigate("/contactus")}
            >
              Book A Counselling Session
            </button>
          </div>
          <div className="exam-svg col-lg-6 col-12 text-center text-lg-end">
            <img
              src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/english-test.svg?updatedAt=1695409912909"
              alt="english proficiency test"
            />
          </div>
        </div>

        {/* english test details */}
        <div className="exam-card-container row padding-yt align-items-start">
          <div className="col-lg-3 col-12 exam-card">
            <img
              src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/IELTS_logo.svg.png?updatedAt=1696334899361"
              alt=""
            />
            <p>
              <b>International English Language Testing System:</b> A globally
              recognized English language proficiency test, IELTS is essential
              for academic and immigration purposes.{" "}
            </p>
          </div>
          <div className="col-lg-3 col-12 exam-card">
            <img
              src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/TOEFL_Logo.svg.png?updatedAt=1696334313289"
              alt=""
            />
            <p>
              <b>Test of English as a Foreign Language:</b> The foremost
              assessment of academic English communication, globally recognized
              and trusted; Entirely focused on academic English proficiency.
            </p>
          </div>
          <div className="col-lg-3 col-12 exam-card">
            <img
              src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/pte%20exam.png?updatedAt=1696334761990"
              alt=""
            />
            <p>
              <b>Pearson Test of English:</b> The world's top computer-based
              English language test, is trusted by institutions and governments
              globally.
            </p>
          </div>
          <div className="col-lg-3 col-12 exam-card">
            <img
              src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/duolingo.png?updatedAt=1696334877828"
              alt=""
            />
            <p>
              <b>Duolingo English Test:</b> An increasingly popular choice, the
              Duolingo English Test is a modern and efficient assessment.{" "}
            </p>
          </div>
        </div>

        {/* standar test */}
        <div className="row row-gap-5 padding-yt flex-row flex-lg-row-reverse">
          <div className="col-lg-5 col-12 text-center text-lg-start">
            <h1 className="heading">
              Standardized <span className="highlight">Test</span>{" "}
            </h1>
            <p>
              Unlock your potential and conquer standardized tests with SkyDoor.
              Our expert-led courses and proven strategies ensure you're ready
              for success. We tailor our training to elevate your performance
              and pave the way for your academic journey. Choose SkyDoor for a
              streamlined path to excellence in standardized testing.
            </p>
            <button
              onClick={() => navigate("/contactus")}
              className="btn btn-round btn-outline btn-hover"
            >
              Book A Counselling Session
            </button>
          </div>
          <div className="exam-svg col-lg-7 col-12 text-center text-lg-start">
            <img
              src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/general-test.svg?updatedAt=1695448840418"
              alt="english proficiency test"
            />
          </div>
        </div>

        {/* standard exam details */}
        <div className="exam-card-container row padding-yt align-items-start">
          <div className="col-lg-4 col-12 exam-card">
            <img
              src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/SAT_logo.svg.png?updatedAt=1696335143896"
              alt=""
            />
            <p>
              <b>Scholastic Assessment Test:</b> The SAT, a widely employed
              standardized test for U.S. college admissions, plays a pivotal
              role in the application process.
            </p>
          </div>
          <div className="col-lg-4 col-12 exam-card">
            <img
              src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/GRE_logo.svg.png?updatedAt=1696335201174"
              alt=""
            />
            <p>
              <b>Graduate Record Examination:</b> The GRE Test stands as the
              globally acclaimed admissions test for graduate and professional
              schools, recognized for its widespread use worldwide.
            </p>
          </div>
          <div className="col-lg-4 col-12 exam-card">
            <img
              src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/GMAT_LOGO.svg.png?updatedAt=1696335315241"
              alt=""
            />
            <p>
              <b>Graduate Management Admission Test:</b> The GMAT is a computer
              adaptive test evaluating analytical, writing, quantitative,
              verbal, and reading skills in written English. It's a key
              requirement for admission to graduate management programs,
              including MBAs.
            </p>
          </div>
        </div>
        <div className="d-flex flex-column align-items-start">
          <button
            type="button"
            className="btn btn-link"
            onClick={() => navigate("/ielts-coaching-in-indore")}
          >
            IELTS Coaching in Indore
          </button>
          <button
            type="button"
            className="btn btn-link"
            onClick={() => navigate("/overseas-education-consultant/indore")}
          >
            Overseas Education Consultant in Indore
          </button>
          <button
            type="button"
            className="btn btn-link"
            onClick={() => navigate("/study-abroad-consultants/madhyapradesh")}
          >
            Study Abroad Consultants in Madhya Pradesh
          </button>
        </div>
      </div>
    </>
  );
};

export default ExamsPage;
