import React from 'react'
import visaInfoData from '../assets/data/visaInfoData';
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';

const VisaPage = () => {
  const navigate = useNavigate();
  return (
    <>
    <Helmet>
        <title>Visa | SkyDoor EdTech International</title>
        <meta name="description" content="Navigating the intricacies of the visa application process can be daunting, but at SkyDoor, we've streamlined it for you." />
        <meta name="keywords" content="student visa assistance in Indore, student visa, student visa services, student visa services in Indore, student visa Germany, best study visa services, best study abroad visa consultants, Study abroad visa, study visa UK, study visa Canada " />
        <meta name="author" content="SkyDoor" />
        <meta name="author" content="Priyanshi Agrawal" />
        <meta property="og:title" content="Visa| SkyDoor EdTech International" />
        <meta property="og:description" content="Navigating the intricacies of the visa application process can be daunting, but at SkyDoor, we've streamlined it for you." />
        <meta property="og:image" content="../assets/images/brand-logo.png" />
        <meta property="og:url" content="https://skydooredtech.in/visa" />
      </Helmet>
      <div className='visa-container container-fluid padding-x margin-top'>
        <div className="row">
          <div className="header-left col-md-5 col-12">
            <h1 className='heading'>Student <span className="highlight">Visa</span></h1>
            <p className='sub-heading'>Navigating the intricacies of the visa application process can be daunting, but at SkyDoor, we've streamlined it for you. Our visa services are designed to make your journey towards studying abroad or pursuing a career overseas smooth and hassle-free.
            </p>
            <button className="btn btn-round btn-outline btn-hover" onClick={() => navigate("/contactus")} >Book A Counselling Session</button>
          </div>
          <div className="header-right col-md-7 col-12">
            <img src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/visa-bg.svg?updatedAt=1696244927766" alt="visa application indore" />
          </div>
        </div>

        <h1 className="heading padding-yt text-center">Our Proficiency <span className="highlight">Encompasses</span></h1>

        {/* mapping details of visa */}
        <div className="visa-table-container padding-yt">
          <table className='table visa-table mx-auto table-striped'>
            <tbody>
              {
                visaInfoData.map((item) => (
                  <tr>
                    <td className='text-center'><img src={item.url} alt={item.country} /></td>
                    <td className='align-middle'>{item.text}</td>
                  </tr>
                ))
              }

            </tbody>

          </table>
        </div>

      </div>
    </>

  )
}

export default VisaPage