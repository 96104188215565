import React from 'react'
import countryData from '../assets/data/countryData' // import country data from array file

// this is the 'country" section of "home" page

export default function countrySection() {
    return (
        <div className='country-section container-fluid padding-xy text-center'>
            <h1 className="heading">Navigate the Entire World and  <span className='highlight'> Find the Best Country</span></h1>

            {/* mapping the country data */}
            <div className="country-container padding-yt">
                {countryData.map((item)=>(
                    // country item : x
                    <div className="country-card" key={item.id}>
                        <img src={item.url} alt={item.country} className='country-img' />
                        <p>{item.country}</p>
                    </div>
                ))}    
         
           </div>
        </div>
  )
}
