import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import testimonialData from "../assets/data/testimonialData";

export default class testimonialSection extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <div className="testimonial-section container-fluid text-center">
        {/* quotes element */}
        <div className="testimonial-quote">
        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M0 216C0 149.7 53.7 96 120 96h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V320 288 216zm256 0c0-66.3 53.7-120 120-120h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H320c-35.3 0-64-28.7-64-64V320 288 216z"/></svg>
        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M448 296c0 66.3-53.7 120-120 120h-8c-17.7 0-32-14.3-32-32s14.3-32 32-32h8c30.9 0 56-25.1 56-56v-8H320c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64h64c35.3 0 64 28.7 64 64v32 32 72zm-256 0c0 66.3-53.7 120-120 120H64c-17.7 0-32-14.3-32-32s14.3-32 32-32h8c30.9 0 56-25.1 56-56v-8H64c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64h64c35.3 0 64 28.7 64 64v32 32 72z"/></svg>
        </div>
        {/* wave element */}
        <div className="testimonial-wave">
            <img src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/wave.png?updatedAt=1693032265566" alt="" />
            <img src="https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/wave.png?updatedAt=1693032265566" alt="" />
        </div>
             <h1 className='heading'>The Value in <span className='highlight'>Our Customers' Words</span> </h1>
        <div className="tetsimonail-container padding-yt">
        <Slider {...settings}>
            {testimonialData.map((data)=>(
                <div className="testimonial-item" key={data.id}>
            <p className="testimonial-text">{data.testimonial}</p>
            <h4 className="testimonial-name">{data.name}</h4>
            <h5 className="testimonial-university">{data.university}</h5>
          </div>
            ))}
          
          
        </Slider>
        </div>
        
      </div>
    );
  }
}