const resultData =[
    {
        id:1,
        url:'https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/result_admit_venkat.jpeg?updatedAt=1706627848085',
        category:'admit'
    },
    {
        id:2,
        url:'https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/result_exam_yash.jpeg?updatedAt=1706627848440',
        category:'exam'
    },
    {
        id:3,
        url:'https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/result_exam_kashish.jpeg?updatedAt=1706627848467',
        category:'exam'
    },
    {
        id:4,
        url:'https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/result_admit_venkat2.jpeg?updatedAt=1706627848549',
        category:'admit'
    },
    {
        id:5,
        url:'https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/result_admit_venkat3.jpeg?updatedAt=1706627848585',
        category:'admit'
    },
    {
        id:6,
        url:'https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/result_exam_mayank.jpeg?updatedAt=1706627848574',
        category:'exam'
    },
    {
        id:7,
        url:'https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/result_admit_arti.jpg?updatedAt=1706688230986',
        category:'admit'
    },
    {
        id:8,
        url:'https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/result_admit_anjali.jpg?updatedAt=1706688230956',
        category:'admit'
    },
    {
        id:9,
        url:'https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/result_admit_prashima.jpg?updatedAt=1706688230960',
        category:'admit'
    },
    {
        id:10,
        url:'https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/result_admit_tulsi.jpg?updatedAt=1706688230955',
        category:'admit'
    },
    {
        id:11,
        url:'https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/result_admit_kavya.jpg?updatedAt=1706688230899',
        category:'admit'
    },
    {
        id:12,
        url:'https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/result_admit_mihika.jpg?updatedAt=1706688230896',
        category:'admit'
    },
    {
        id:13,
        url:'https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/result-visa-abhinav-visa%20aprroved.png?updatedAt=1706346912526',
        category:'visa'
    },
    {
        id:14,
        url:'https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/result-visa-sushim-visa%20aprroved.png?updatedAt=1706346911603',
        category:'visa'
    },
    {
        id:15,
        url:'https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/result-exam-Arushi.png?updatedAt=1706346914030',
        category:'exam'
    },
    {
        id:16,
        url:'https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/result-exam-mihika%20report.png?updatedAt=1706346913963',
        category:'exam'
    },
    {
        id:17,
        url:'https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/result-admit-sushim%20admit.png?updatedAt=1706346913236',
        category:'admit'
    },
    {
        id:18,
        url:'https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/result-admit-abhinav%20admit.png?updatedAt=1706346913152',
        category:'admit'
    },
    {
        id:19,
        url:'https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/result-admit-Arushi%20Monash.png?updatedAt=1706346912970',
        category:'admit'
    },
    {
        id:20,
        url:'https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/result_exam_rajshree.jpeg?updatedAt=1706627848133',
        category:'exam'
    },
    {
        id:21,
        url:'https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/result_exam_priyanka_rajput.jpeg?updatedAt=1706627848136',
        category:'exam'
    },
    {
        id:22,
        url:'https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/result_exam_priyanka.jpeg?updatedAt=1706627848118',
        category:'exam'
    },
    {
        id:23,
        url:'https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/result_exam_ronak.jpeg?updatedAt=1706688251946',
        category:'exam'
    },
    {
        id:24,
        url:'https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/result_exam_devanshi.jpeg?updatedAt=1706688251817',
        category:'exam'
    },
    {
        id:25,
        url:'https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/result_exam_saurabh.jpeg?updatedAt=1706789229322',
        category:'exam'
    },
    {
        id:26,
        url:'https://ik.imagekit.io/amy8byzdtw/SkyDoor%20Website%20assets/result_exam_achyut.jpeg?updatedAt=1706896967097',
        category:'exam'
    },
]

resultData.reverse();


export default resultData;